import axios from 'axios';
import type { AxiosResponse, InternalAxiosRequestConfig, AxiosError, Method } from 'axios';
import { notification } from '@/components/Message';
import { BaseURL, DataCode, TokenKey } from '@/const/serviceEnv';
import Cookies from 'js-cookie';

interface CommonObject {
  [key: string]: any;
}

interface PendingType {
  url?: string;
  method?: Method;
  params: any;
  data: any;
  cancel: Function;
}

interface ResponseType {
  success: boolean;
  code: number | string;
  message: string;
  data: any;
}

// 登录失效
const { CancelToken } = axios;
const source = CancelToken.source();
const service = axios.create({
  baseURL: BaseURL,
  timeout: 30000,
});

const pending: PendingType[] = [];

// 移除重复请求
const removePending = (config: InternalAxiosRequestConfig) => {
  for (const key in pending) {
    if (Object.prototype.hasOwnProperty.call(pending, key)) {
      const item: number = +key;
      const list: PendingType = pending[key];
      // 当前请求在数组中存在时执行函数体
      if (
        list.url === config.url &&
        list.method === config.method &&
        JSON.stringify(list.params) === JSON.stringify(config.params) &&
        JSON.stringify(list.data) === JSON.stringify(config.data)
      ) {
        // 执行取消操作
        list.cancel('操作太频繁，请稍后再试');
        // 从数组中移除记录
        pending.splice(item, 1);
      }
    }
  }
};

service.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    removePending(config);

    const __config = config;
    if (Cookies.get(TokenKey)) {
      __config.headers.authority_token = Cookies.get(TokenKey);
    }

    __config.url = `${config.url}?__date=${new Date().getTime()}`;

    return Promise.resolve({
      ...__config,
      cancelToken: source.token,
    });
  },
  (err: AxiosError) => {
    !!err.message &&
      notification.error({
        message: '提示',
        description: err.message,
      });
    return Promise.reject(new Error(err.message || '网络错误'));
  },
);

service.interceptors.response.use(
  (res: AxiosResponse<ResponseType, CommonObject>) => {
    const { status, data, statusText } = res;
    return new Promise((resolve, reject) => {
      if (status < 200 || status > 300) {
        notification.error({
          message: `请求错误: ${status}`,
          description: statusText,
        });
        reject(new Error(`请求错误: ${status}`));
      } else if (!data.success || +data.code !== DataCode.get('SUCCESS')) {
        if (+data.code === DataCode.get('LOGIN_WARNING')) {
          return reject(data);
        } else {
          notification.error({
            message: '提示',
            description: data.message,
          });
          reject(new Error(data.message || '网络错误'));
        }
      } else {
        resolve(res);
      }
    });
  },
  (err: AxiosError) => {
    if (err.message !== '登录失效') {
      err.message &&
        notification.error({
          message: '提示',
          description: err.message,
        });
    }
    return Promise.reject(new Error(err.message || '网络错误'));
  },
);

export default service;
