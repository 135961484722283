import React from 'react';
import PlusRouter from '@fle-ui/plus-router';
import routerConfig from '@/const/route.config';
import { ROUTER_BASE } from '@/const/serviceEnv';

const RouterPack: React.FC = () => {
  return <PlusRouter loading={<></>} routes={routerConfig} basename={ROUTER_BASE} />;
};

export default RouterPack;
