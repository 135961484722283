import qs, { ParsedQs } from 'qs';
import { loginLink } from '@/const/serviceEnv';

/**
 * 获取路径query参数
 */
export const getQuery = (): ParsedQs =>
  qs.parse(typeof window !== 'undefined' ? window.fleLocation.search.substring(1) : '');

/**
 * 金额格式化
 * @param data
 */
type moneyType = number | string;

interface ShowMoneyType {
  (data: moneyType): string;
}
export const showMoney: ShowMoneyType = (data) => (+data / 100).toFixed(2);

/**
 * 匹配登录门户的loginLink
 */
export const getLoginLink = (code?: string) => {
  return code ? `${loginLink}/${code}` : loginLink;
};

export function getMainDomain(): string {
  if (typeof window !== 'undefined') {
    const { hostname } = window.fleLocation;
    const parts = hostname.split('.').reverse();

    // parts[0] 是顶级域名（例如，'com'），parts[1] 是主域名（例如，'google'）
    if (parts && parts[1]) {
      return `${parts[1]}.${parts[0]}`;
    }
    return hostname;
  }

  return '';
}
