import { makeAutoObservable, runInAction } from 'mobx';
import request from '@/tools/httpClient';
import { common } from '@/api';
import Cookies from 'js-cookie';

import type { StateProps } from '@/types/common/store';
import type { LoginType } from '@/const/application';

export interface PageQueryProps {
  loginType: LoginType;
  loginLink: string;
  portalName: string;
  backendLogo: string;
  portalLogo: string;
  propagandaMap: string;
  telecomBusinessPermitNo: string;
  websiteIcpRecord: string;
  networkSecurityRecord: string;
  websiteCopyright: string;
  slogan: string;
  welcomeWords: string;
}

const SmartercarpPageQuery = {
  loginType: '90',
  loginLink: `${window.fleLocation.host}/account/login`,
  portalName: '智鲤平台',
  portalLogo: '//oss.elebuys.com/logo/smartercarp/smartercarp-text-logo.png',
  propagandaMap: '//oss.elebuys.com/resource/elebuys/202401290640290004129293.png',
  telecomBusinessPermitNo: '浙ICP备17028214号-38',
  websiteIcpRecord: '浙B2-20170751',
  networkSecurityRecord: '33010602008661',
  websiteCopyright: '杭州飞象企服网络技术有限公司',
  slogan: '全域数字化解决方案',
  welcomeWords: '欢迎使用智鲤平台',
};
const SelfSupplierPageQuery = {
  loginType: '40',
  loginLink: `${window.fleLocation.host}/account/login`,
  portalName: '智鲤供应商平台',
  portalLogo: '//oss.elebuys.com/logo/smartercarp/smartercarp-text-logo.png',
  propagandaMap: '//oss.elebuys.com/resource/elebuys/202401290640290004129293.png',
  telecomBusinessPermitNo: '浙ICP备17028214号-38',
  websiteIcpRecord: '浙B2-20170751',
  networkSecurityRecord: '33010602008661',
  websiteCopyright: '杭州飞象企服网络技术有限公司',
  slogan: '中大型企业专属数智升级平台',
  welcomeWords: '欢迎使用供应商平台',
};

class CommonStore {
  [key: string]: any;

  pageQuery: PageQueryProps | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  // 获取验证码（通用）
  getSmsCode = (params: any, systemId: string) => {
    return new Promise((resolve, reject) => {
      try {
        request
          .post(
            common.smsCode,
            {
              ...params,
              companyName: this?.pageQuery?.portalName || '万鲤商联',
            },
            {
              headers: {
                system_id: systemId,
              },
            },
          )
          .then(({ data }) => {
            if (data.success) {
              resolve(true);
            }
            reject();
          });
      } catch (ex) {
        reject();
      }
    });
  };
  // 获取滑块验证
  getPicture = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.getPicture, params).then((res) => {
          resolve(res.data);
        });
      } catch (ex) {
        reject();
      }
    });
  };
  // 验证滑块验证
  reqCheck = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.reqCheck, params).then((res) => {
          resolve(res.data);
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取登录页信息
  loginPageQuery = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.loginPageQuery, params).then(({ data: { success, data } }) => {
          if (success) {
            runInAction(() => {
              const DefaultPageQuery = window.fleLocation.host.includes('supplier.')
                ? SelfSupplierPageQuery
                : SmartercarpPageQuery;
              
              const pageQuery = data || DefaultPageQuery;
              this.pageQuery = {
                ...pageQuery,
                loginType: pageQuery.loginType?.toString(),
              };

              Cookies.set('loginType', pageQuery.loginType, { expires: 1 });
              Cookies.set('loginLink', pageQuery.loginLink, { expires: 1 });
            });
            resolve(data?.data);
          }
        });
      } catch (ex) {
        reject();
      }
    });
  };

  updateState = (params: StateProps) => {
    for (const [key, value] of Object.entries(params)) {
      this[key] = value;
    }
  };
}

export default new CommonStore();
