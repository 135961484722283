import type { ConfigProviderProps } from 'antd/lib/config-provider';

const Theme: ConfigProviderProps['theme'] = {
  token: {
    colorPrimary: '#2c68ff',
    colorLink: '#2c68ff',
    borderRadius: 8,
  },
  components: {
    Select: {
      singleItemHeightLG: 54,
      multipleItemHeightLG: 54,
    },
  },
};

export default Theme;
